import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { PatientIDContext } from '../../Contexts';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import logo from '../logo.PNG';
import { getTrial } from '../../util/reportAPI';
import { listGopros, connectGopro, removeGopro } from '../../util/recordingAPI';
import TrialRow from '../patients/patientDashboard/TrialRow';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import '../../../dist/assets/stylesheets/css/device.css'
import { checkDevice } from "../../util/recordingAPI";

const CommonButton = styled(Button)({
  color: 'black',
  '& svg': {
    fontSize: '1.2rem',
    marginRight: '5px',
  }
});

function SetupPage() {
  const history = useHistory();
  const [submit, setSubmit] = useState(true);
  const [formDisabled, setFormDisabled] = useState(false);
  const [isScanning, setIsScanning] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [gopros, setGopros] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isConnecting, setIsConnecting] = useState({});
  const [anyConnecting, setAnyConnecting] = useState(false);
  const [isRemoving, setIsRemoving] = useState({});
  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    handleScan()
  }, [])

  useEffect(() => {
    checkDevice()
  }, [])

  useEffect(() => {
    if (errorMsg) {
      setOpenDialog(true);
    }
  }, [errorMsg])

  const handleClick = () => {
    history.goBack();
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleScan = () => {
    setIsScanning(true);
    listGopros()
      .then(res => {
        if (res.status == 'success') {
          setGopros(Object.values(res.result));
        } else {
          setErrorMsg(res.reason || 'Unknown error during scan');
          setIsScanning(false);
        }
      })
      .catch(error => {
        setErrorMsg("Failed to connect to the hardware. Please ensure that you followed the instructions.");
        setIsScanning(false);
      })
      .finally(() => setIsScanning(false));
  };

  const handleUpdate = () => {
    setIsUpdating(true);
    fetch(`${window.UPDATE_URL}/api/update`)
      .then(response => response.json())
      .catch(error => {
        setErrorMsg('Error doing the update:', error);
        setIsUpdating(false)
      })
      .finally(() => setIsUpdating(false));
  };

  const connectGoproUI = (gopro) => {
    // Start connecting: set the specific gopro as connecting
    setIsConnecting(prevState => ({ ...prevState, [gopro.name]: true }));
    setAnyConnecting(true)

    connectGopro(gopro.name)
      .then(res => {
        // Handle non-success response
        if (res.status !== 'success') {
          setErrorMsg(res.reason || 'Unknown error during connection');
        }
        // Potentially update gopro list or status, assuming handleScan does this
        handleScan();
        setAnyConnecting(false)
      })
      .catch(err => {
        // Handle errors such as network issues
        setErrorMsg(err.message || 'Network error');
      })
      .finally(() => {
        // Stop connecting: set the specific gopro as not connecting
        // Ensures the UI reflects the latest state
        setIsConnecting(prevState => ({ ...prevState, [gopro.name]: false }));
        setAnyConnecting(false)
      });
  };


  const removeGoproUI = (gopro) => {
    setIsRemoving({ ...isRemoving, [gopro.name]: true });
    removeGopro(gopro.name)
      .then(res => {
        if (res.status !== 'success') {
          setErrorMsg(res.reason || 'Unknown error during connection');
        }
        handleScan();
      })
      .catch(err => {
        setErrorMsg(err.message || 'Network error');
      })
      .finally(() => {
        setIsRemoving({ ...isRemoving, [gopro.name]: false });
      });
  };

  function GoProTable(data) {
    return (
      <section className='patient-table container'>
        <table>
          <tbody>
            <tr>
              <td colSpan="5" style={{ height: "fit-content" }}>
                <input id='search-bar' placeholder="Search..." onChange={e => setQuery(e.target.value)}></input>
              </td>
            </tr>
            <tr>
              <th>Gopro ID</th>
              <th>Availability</th>
              <th>Status</th>
              <th>Connect</th>
              <th>Remove</th>
            </tr>
            {gopros.map((gopro) => (
              <tr key={gopro.name} style={{ backgroundColor: (gopro.available === 'yes' && gopro.connected === 'yes') ? 'green' : 'inherit' }}>
                <td>{gopro.name}</td>
                <td>{gopro.available ? "yes" : "no"}</td>
                <td style={{ color: gopro.connected ? 'green' : 'inherit' }}>
                  {gopro.connected ? "Connected" : "Not Connected"}
                </td>
                <td>
                  <button
                    disabled={anyConnecting}
                    className='connect-button'
                    onClick={() => connectGoproUI(gopro)}>
                    {(isConnecting[gopro.name] && !(gopro.available === 'yes' && gopro.connected === 'yes')) ? (
                      <span className='spinner'></span>
                    ) : (
                      "Connect"
                    )}
                  </button>
                </td>
                <td>
                  <button
                    className='remove-button'
                    onClick={() => removeGoproUI(gopro)}>
                    {(isRemoving[gopro.name] && !(gopro.available === 'yes' && gopro.connected === 'yes')) ? (
                      <span className='spinner'></span>
                    ) : (
                      "Remove"
                    )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {gopros.length == 0 && <h5 style={{ textAlign: 'center', marginTop: '10px', fontWeight: 400, color: 'gray' }}>No devices found.</h5>}
      </section>
    );
  }


  const styles = {
    marginTop: '30px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  };

  return (
    <div className='parent-container'>
      <div className='container'>
        <h1 className='page-title'>
          Devices
        </h1>
      </div>

      <div style={styles}>
        <button disabled={isScanning} className='scan-button' onClick={handleScan}>{!isScanning ? 'Scan' : <span className='spinner'></span>}</button>
        <button type='submit' className='update-button' onClick={handleUpdate}>{!isUpdating ? 'Update Software' : <span className='spinner'></span>}</button>
      </div>
      <GoProTable data={gopros} />
      {/* pop up error */}
      <Box>
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {errorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CommonButton onClick={handleDialogClose}>Close</CommonButton>
          </DialogActions>
        </Dialog>
      </Box>
      {/* {errorMsg &&
        <div className="error-modal">
          <div className="error-content">
            <h5>Error</h5>
            <p>{errorMsg}</p>
            <button className='close-button' onClick={() => setErrorMsg(null)}>Close</button>
          </div>
        </div>
      } */}
    </div>
  );
}

export default SetupPage;
