import axios from 'axios'
console.log(axios)
export async function startRecording(patientID, res, assessment, calibrationID) {
    const token = localStorage.getItem("token");
    console.log('started recording')
    var url = "";
    if(assessment.substr(0, 5) == "calib") {
      url = `/api/start-record?patient_id=${patientID}&res=${res}&assessment="${assessment}"&length=${localStorage.getItem('checkerboardLength')}&width=${localStorage.getItem('checkerboardWidth')}&token=${token}`;
    } else {
      url = `/api/start-record?patient_id=${patientID}&res=${res}&assessment="${assessment}"&calibration_id=${calibrationID}&token=${token}`;
    }

    return fetch(window.RECORDING_URL + url,{
      headers:{
        Authorization:'Bearer '+token
      }
    } )
      .then(res => res.json())
      .catch(err => console.log(err))
  }

export async function stopRecording() {
    const token = localStorage.getItem("token");
    return fetch(window.RECORDING_URL + `/api/stop-record?token=${token}`,{
      headers:{
        Authorization:'Bearer '+token
      }
    })
           .then(res => console.log(res))
           .catch(err => console.log(err))
}

export async function pauseRecording() {
    return fetch(window.SERVER_URL + '/api/pause-record',{
      headers:{
        Authorization:'Bearer '+token
      }
    })
           .then(res => console.log(res))
           .catch(err => console.log(err)) 
}

export async function resumeRecording() {
    return fetch(window.SERVER_URL + '/api/resume-record',{
      headers:{
        Authorization:'Bearer '+token
      }
    })
           .then(res => console.log(res))
           .catch(err => console.log(err)) 
}

export async function clearRecording(trialID) {
    return fetch(window.SERVER_URL + `/api/clear-record?trial_id=${trialID}`,{
      headers:{
        Authorization:'Bearer '+token
      }
    })
           .then(res => res.json())
           .then(err => console.log(err))
}

export async function checkDevice() {
  const token = localStorage.getItem("token");
  const targ = `?token=${token}`;
  return fetch(window.RECORDING_URL + "/check" + targ,{
    headers:{
      Authorization:'Bearer '+ token
    }
  })
         .then(res => res.json())
         .catch(err => console.log(err))
}

// export async function getTrialType() {
//     return fetch('/api/get-trial-type')
//            .then(res => res.json())
//            .catch(err => console.log(err))
// }

export async function getCalibrationVideos() {
  const token = localStorage.getItem("token");
  const targ = `?token=${token}`;
  return fetch(window.SERVER_URL + '/a/get-calibration-list' + targ,{
    headers:{
      Authorization:'Bearer '+token
    }
  })
         .then(res => res.json())
         .catch(err => console.log(err))
}

export async function getCountVideos(calib_id) {
  const token = localStorage.getItem("token");
  return fetch(`${window.SERVER_URL}/a/count-videos?calib_id=${calib_id}`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
  .then(res => res.json())
  .then(data => data.video_count)
  .catch(err => console.error("Error fetching video count:", err));
}


export function getCalibrationVideoUrl(id){
  const token = localStorage.getItem("token");
  return axios.get(window.SERVER_URL + '/a/get-calibration-video-url?id='+id ,{
    headers:{
      Authorization:'Bearer '+token
    }
  }).then(res=>res.data)
}

export async function deleteCalibration(id) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.delete(window.SERVER_URL + '/a/delete-calibration?id=' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    return response.data;
  } catch (err) {
    console.error("Error deleting calibration:", err);
    throw err;
  }
}

export function getRecordingVideoUrl(id){
  const token = localStorage.getItem("token");
  return axios.get(window.SERVER_URL + '/a/get-recording-video-url?id='+id ,{
    headers:{
      Authorization:'Bearer '+token
    }
  }).then(res=>res.data)
}
// export async function getTrialTypeDetails(trialType) {
//     return fetch(`/api/get-trial-type-details?trial_type=${trialType}`)
//            .then(res => res.json())
//            .catch(err => console.log(err))
// }

export function saveNotes(trialID, notes) {
    return fetch(window.SERVER_URL + `/api/save-record?trial_id=${trialID}&notes=${notes}`,{
      headers:{
        Authorization:'Bearer '+token
      }
    })
           .then(res => console.log(res))
           .catch(err => console.log())
}

// export async function submitDimensions(length, width) {
//     return fetch(`/api/submit-dimensions?length=${length}&width=${width}`)
//       .then(res => res.json())
//       .catch(err => console.log(err))
//   }

export function listGopros() {
  const token = localStorage.getItem("token");
  return fetch(window.RECORDING_URL + "/api/list-gopros", {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
  .then(res => {
    if (!res.ok) { // Check if the response status is not in the 2xx range
      throw new Error(`HTTP error! status: ${res.status}`); // Throw an error to be caught in the catch block
    }
    return res.json(); // Return the response as JSON if the status is OK
  });
}

// export function connectGopro(mac) {
//   const token = localStorage.getItem("token");
//   return fetch(window.RECORDING_URL + `/api/connect-gopro?mac=${mac}`,{
//     headers:{
//       Authorization:'Bearer '+token
//     }
//   })
//          .then(res => res.json())
// }

// export function removeGopro(mac) {
//   const token = localStorage.getItem("token");
//   return fetch(window.RECORDING_URL + `/api/remove-gopro?mac=${mac}`,{
//     headers:{
//       Authorization:'Bearer '+token
//     }
//   })
//          .then(res => res.json())
// }

export function connectGopro(name) {
  const token = localStorage.getItem("token");
  return fetch(window.RECORDING_URL + `/api/connect-gopro?name=${name}`,{
    headers:{
      Authorization:'Bearer '+token
    }
  })
         .then(res => res.json())
}

export function removeGopro(name) {
  const token = localStorage.getItem("token");
  return fetch(window.RECORDING_URL + `/api/remove-gopro?name=${name}`,{
    headers:{
      Authorization:'Bearer '+token
    }
  })
         .then(res => res.json())
}